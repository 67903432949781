import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faMobileAlt,
  faLightbulb,
  faSortDown,
  faSortUp,
  faQuestion,
  faSearch,
  faThLarge,
  faSync,
  faReceipt,
  faBalanceScale,
  faPenNib,
  faTruck,
  faDolly,
  faCheckDouble,
  faDollarSign,
  faTimes,
  faTimesCircle,
  faLifeRing,
  faUserPlus,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faFilter,
  faPlus,
  faCog,
  faSignOutAlt,
  faCalendar,
  faEyeSlash,
  faEye,
  faCheck,
  faTrash,
  faExclamation,
  faPrint,
  faLink,
  faAd,
  faFileDownload,
  faExclamationTriangle,
  faExclamationCircle,
  faPhoneAlt,
  faUserFriends,
  faLock,
  faSms,
  faCommentDots,
  faBookReader,
  faPlayCircle,
  faArrowRight,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle as check, faCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faSpinner,
  faMobileAlt,
  faLightbulb,
  faSortDown,
  faSortUp,
  faQuestion,
  faSearch,
  faThLarge,
  faSync,
  faReceipt,
  faBalanceScale,
  faPenNib,
  faTruck,
  faDolly,
  faCheckDouble,
  faDollarSign,
  faQuestion,
  faTimes,
  faTimesCircle,
  faLifeRing,
  faUserPlus,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faFilter,
  faPlus,
  faCog,
  faSignOutAlt,
  faCalendar,
  faEye,
  faEyeSlash,
  check,
  faCheck,
  faExclamation,
  faTrash,
  faPrint,
  faLink,
  faAd,
  faFileDownload,
  faExclamationTriangle,
  faPhoneAlt,
  faCircle,
  faUserFriends,
  faLock,
  faSms,
  faCommentDots,
  faBookReader,
  faExclamationCircle,
  faPlayCircle,
  faArrowRight,
  faArrowLeft
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
