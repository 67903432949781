import { TheMask } from 'vue-the-mask'
import KInputWrapper from '../InputWrapper'
import InputMixin from '@/mixins/input'
export default {
  name: 'KNumericInput',
  components: {
    KInputWrapper,
    TheMask
  },
  mixins: [InputMixin],
  props: {
    mask: {
      type: String,
      default: '#'.repeat(255)
    },
    inputRef: {
      type: String,
      default: ''
    },
    userlanguage: {
      type: String
    }
  },
  data: () => ({
    masks: {
      tel: '(###) ###-####'
    }
  }),
  computed: {
    vMask() {
      return this.masks[this.type]
    }
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (((keyCode < 48 || keyCode > 57) && keyCode !== 46) || keyCode === 173) {
        // 46 is dot
        $event.preventDefault()
      }
    },
    handleBlur(error) {
      this.$emit('error-check', error)
      this.$emit('blur')
    }
  }
}
