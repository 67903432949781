import KFrame from '@/components/Frame'
import KEmployeeAccess from '@/components/Merchant/Modals/Employees/EmployeeAccess'
import { isLearning } from '@/utils/learning-platform'

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'KNavbarDropdown',
  components: {
    KFrame,
    KEmployeeAccess
  },
  data: () => ({
    optionsVisible: false,
    valueString: '',
    filteredOptions: [],
    localValue: '',
    search: '',
    showLogoutModal: false,
    secureAccess: false
  }),
  inject: ['productTypeIsLoading'],
  watch: {
    async optionsVisible(val) {
      if (val) {
        try {
          this.getSecureAccessStatus({ email: this.merchant.email })
          await this.fetchStores({ merchant_id: this.merchant.merchant_id })
        } catch (error) {
          this.$toast.open({
            message: error || 'Unable to load merchant stores.',
            type: 'error',
            icon: 'close'
          })
          return error
        }
      }
    },
    localValue(val) {
      if (val) {
        this.optionsVisible = false
        const selected = this.options.find((option) => option.id === val)
        this.changeStore(val)
        this.valueString = `${selected.location}${selected.location && selected.name ? ', ' : ''}${selected.name}`
      }
    },
    async isLoggedIn(val) {
      if (val) {
        try {
          await this.fetchStores({ merchant_id: this.merchant.merchant_id })
        } catch (error) {
          this.$toast.open({
            message: `Stores: ${error}` || 'Unable to load merchant stores.',
            type: 'error',
            icon: 'close'
          })
          return error
        }
      }
    },
    options: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.filteredOptions = val
      }
    },
    showAuthorizationModal(val) {
      if (!val) {
        this.secureAccess = false
        this.$emit('closeNav')
        this.handleOutsideFocus()
      }
    }
  },
  async mounted() {
    this.localValue = this.selectedStore.id
    if (this.isLoggedIn) {
      try {
        this.getSecureAccessStatus({ email: this.merchant.email })
        await this.fetchStores({ merchant_id: this.merchant.merchant_id })
      } catch (error) {
        this.$toast.open({
          message: error || 'Unable to load merchant stores.',
          type: 'error',
          icon: 'close'
        })
        return error
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/getAuthState',
      options: 'stores/getStores',
      selectedStore: 'stores/getSelectedStore',
      merchant: 'auth/getUser',
      storeMerchantAccess: 'events/getMerchantAccess',
      showAuthorizationModal: 'events/getShowAuthorizationModal',
      productTypeIsLoan: 'stores/getProductTypeIsLoan'
    }),
    merchantAccess: {
      set(val) {
        this.updateAccessStatus(val)
      },
      get() {
        return this.storeMerchantAccess
      }
    }
  },
  methods: {
    ...mapActions({
      fetchStores: 'stores/fetchStores',
      updateAccessStatus: 'events/updateAccessStatus',
      updateShowAuthorizationModal: 'events/updateShowAuthorizationModal',
      getSecureAccessStatus: 'events/getSecureAccessStatus',
      perfomLogout: 'auth/logout'
    }),
    ...mapMutations({
      changeStore: 'stores/updateSelectedStores'
    }),
    handleFocus(e) {
      // Fix IE11 quirks
      if (e.target.tagName === 'DIV') return
      this.optionsVisible = true
    },
    filterOptions() {
      this.filteredOptions = this.options.filter(
        (it) =>
          it.name.toLowerCase().includes(this.search.toLowerCase()) ||
          it.location.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    handleOutsideFocus() {
      this.optionsVisible = false
      this.search = ''
    },
    clearFilter() {
      setTimeout(() => {
        this.filteredOptions = this.options
        this.search = ''
      }, 2000)
    },
    openEmployees() {
      this.$router.push({ name: 'employees' })
      this.optionsVisible = false
    },
    openTeam() {
      this.$router.push({ name: 'team' })
      this.optionsVisible = false
    },
    changePassword() {
      this.$router.push({ name: 'forgot-password', query: { loggedIn: true } })
      this.optionsVisible = false
    },
    toggleMerchant() {
      this.updateShowAuthorizationModal(true)
      this.secureAccess = true
    },
    closeModal() {
      this.updateShowAuthorizationModal(false)
      this.getSecureAccessStatus({ email: this.merchant.email })
      this.secureAccess = false
      if (this.$route.path === '/') {
        this.$router.push('/')
      }
    },
    logout() {
      console.log(isLearning)
      if (isLearning) {
        this.$router.push({ name: 'logout-learning' })
        return
      }

      this.perfomLogout()
      this.$router.push({ name: 'login' })
    }
  }
}
