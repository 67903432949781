import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=5b2662cc"
import script from "./Button.js?vue&type=script&lang=js&external"
export * from "./Button.js?vue&type=script&lang=js&external"
import style0 from "./Button.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports