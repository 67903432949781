import * as rules from 'vee-validate/dist/rules'
import { extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import store from '../store'
import { required, min } from 'vee-validate/dist/rules'
import { validatePhoneNumber } from '@kafene_team/validation.kafene.com'

localize({
  en: {
    messages: {
      chimeBankAccount:
        'To continue with a Chime bank account, please click the button below: "Safely Connect with Stripe".',
      chimeCheckingAccount:
        'To continue with a Chime bank account, please click the button above: "Safely Connect with Stripe".'
    }
  },
  es: {
    messages: {
      chimeBankAccount:
        'Para continuar con una cuenta de banco Chime, por favor haga clic en el botón de abajo: "Safely Connect with Stripe".',
      chimeCheckingAccount:
        'Para continuar con una cuenta de banco Chime, por favor haga clic en el botón de arriba: "Safely Connect with Stripe".'
    }
  }
})

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: en.messages[rule] // assign message
  })
})

extend('required', {
  ...required,
  params: ['errorMessage'],
  message: (fieldName, { errorMessage }) => {
    return errorMessage || `${fieldName} is required.`
  }
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('routing', {
  validate(value) {
    let blacklistedRoutingNumbers = []

    blacklistedRoutingNumbers = store.getters.getPageData?.blacklisted_routing_numbers

    return !blacklistedRoutingNumbers.some((number) => value === number)
  },
  message:
    'We are sorry, we do not currently accept non-traditional bank accounts such as Chime, Simple, or Varo. Please use a traditional bank account to continue.'
})

extend('chimeBankAccount', {
  validate(value) {
    const chimeRoutingNumbers = ['031101279', '103100195']
    return !chimeRoutingNumbers.includes(value)
  }
})

extend('chimeCheckingAccount', {
  validate(value) {
    const chimeRoutingNumbers = ['031101279', '103100195']
    return !chimeRoutingNumbers.includes(value)
  }
})

extend('name', {
  validate(value) {
    return !value.includes(' and ')
  },
  message: 'Applications are individual. Please only enter 1 person’s name'
})

extend('ssnMatch', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  }
})

extend('notIdentical', {
  params: ['otherValue'],
  validate(value, { otherValue }) {
    return value !== otherValue
  },
  message: 'The two fields must be different.'
})

extend('phone', {
  params: ['errorMessage'],
  validate(value, { errorMessage }) {
    const valid = min.validate(value, { length: 10 })

    if (!valid) {
      return errorMessage
    }

    const phoneNumberValidation = validatePhoneNumber(value, {
      allowedCountries: ['US', 'CA', 'BS', 'PR']
    })
    if (phoneNumberValidation) {
      return true //TODO: part of KFN-7131. Blocked until bussiness decides if we are ok with this kind of validation
    }

    return true
  }
})
