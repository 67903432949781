import { login, logout, setLogin } from './login'
import { forgotPassword, changePassword, verifyToken } from './password'
import { checkIfUserExists, updateUser, createAccount, userAccountConfirmation, checkUserPhone } from './user'

const actions = {
  login,
  logout,
  setLogin,
  verifyToken,
  updateUser,
  createAccount,
  changePassword,
  forgotPassword,
  checkIfUserExists,
  userAccountConfirmation,
  checkUserPhone
}

export default actions
