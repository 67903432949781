import KTooltip from '@/components/Tooltip'
import KCalculator from '@/components/Calculator'
import KNavbarDropdown from './NavbarDropdown'
import KResourcesDropdown from './ResourcesDropdown'
import KReportsDropdown from './ReportsDropdown'
import { mapActions, mapGetters } from 'vuex'
import { isLearning } from '../../utils/learning-platform'

export default {
  name: 'KNavbar',
  components: {
    KResourcesDropdown,
    KNavbarDropdown,
    KCalculator,
    KReportsDropdown,
    KTooltip
  },
  data: () => ({
    navIsOpen: false,
    showCalculator: false,
    isLearning
  }),
  computed: {
    ...mapGetters({
      productTypeIsLoan: 'stores/getProductTypeIsLoan'
    })
  },
  methods: {
    ...mapActions({
      perfomLogout: 'auth/logout'
    }),
    logout() {
      this.navIsOpen = false
      if (isLearning) {
        this.$router.push({ name: 'logout-learning' })
        return
      }

      this.perfomLogout()
      this.$router.push({ name: 'login' })
    }
  },
  watch: {
    navIsOpen(val) {
      if (!val) {
        window.HubSpotConversations.widget.load()
      } else {
        window.HubSpotConversations.widget.remove()
      }
    }
  }
}
