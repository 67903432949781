import Vue from 'vue'
import { setupCalendar } from 'v-calendar'
import { components } from 'vue-date-of-birth-multi'
import Multiselect from 'vue-multiselect'

Vue.component('KDateMulti', components.KDateMulti)
Vue.component('Multiselect', Multiselect)

import 'vue-date-of-birth-multi/dist/vue-date-of-birth-multi.css'
import App from './App.vue'
import router from './router'
import './utils/font-awesome'
import './utils/vue-pdf'
import './utils/filters'
import './utils/tooltip'
import './utils/global-components'
import api from './utils/api'
import store from './store'
import KToast from './plugins/toast'
import KToastList from './plugins/toastList'
import Pusher from 'pusher-js'
import VueMeta from 'vue-meta'
import LoadScript from 'vue-plugin-load-script'

import './styles/global.css'

setupCalendar({
  datePicker: {
    popover: {
      visibility: 'click'
    }
  }
})

Vue.use(KToast)
Vue.use(KToastList)
Vue.use(VueMeta)
Vue.use(LoadScript)
Vue.config.productionTip = false

const init = async () => await store.dispatch('ui/UpdateApplicationEnvironment')
init()

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/getAuthState']
  if (!from.name) {
    store.commit('setAppLoadingStatus', true)
    next()
  }
  if (to.query.merch) {
    const { merch, adminToken } = to.query
    store.dispatch('auth/logout')
    store.dispatch('auth/setLogin', atob(merch))
    store.commit('setAdminEmail', atob(adminToken).substring(1).slice(0, -1))
    next({ name: 'applications' })
  }
  // this route requires auth, check if logged in... if not, redirect to login page.
  if (isAuthenticated) {
    if (to.meta.auth) {
      if (to.meta.password) {
        next()
      } else next({ name: 'applications' })
    } else next()
  } else {
    if (to.meta.auth) {
      next()
    } else next({ name: 'login' })
  }
})

router.afterEach(() => {
  if (store.getters.getAppLoadingStatus) {
    setTimeout(() => {
      store.commit('setAppLoadingStatus', false)
    }, 3000)
  }
})

if (
  window.location.hostname === 'merchant-dashboard-components.herokuapp.com' ||
  window.location.hostname === 'dev-merchant.kafene.com' ||
  window.location.hostname === 'staging-merchant.kafene.com' ||
  window.location.hostname === 'uat-merchant.kafene.com' ||
  window.location.hostname === 'localhost'
) {
  Vue.config.devtools = true
} else {
  Vue.config.devtools = false
}

new Vue({
  router,
  store,
  computed: {
    token() {
      return store.state.auth.token
    },
    pusherKey() {
      return `${process.env.VUE_APP_PUSHER_APP_KEY}`
    },
    pusherCluster() {
      return `${process.env.VUE_APP_PUSHER_APP_CLUSTER}`
    },
    getMerchantID() {
      return store.getters['auth/getUser'] && store.getters['auth/getUser'].merchant_id
    },
    getID() {
      return store.getters['auth/getUser'] && store.getters['auth/getUser'].id
    }
  },
  watch: {
    token(val) {
      this.setHeader(val)
    }
  },
  created() {
    store.commit('events/SET_UPDATE_LOGOUT_MODAL', false)

    this.setHeader(store.state.auth.token)
    api.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (
          // disabling 401 from logging out user
          // err.response &&
          //   err.response.status === 401 &&
          (err.config && err.config.__isRetryRequest === false) ||
          (err.response && err.response.status === 403)
        ) {
          if (store.getters['auth/getAuthState']) {
            store.dispatch('auth/logout')
            store.dispatch('events/updateShowLogoutModal', true)
          } else {
            store.dispatch('auth/logout')
          }
          reject(err)
        } else if (err.response) {
          reject(err)
        } else if (err.message) {
          reject(err.message)
        } else {
          resolve()
        }
        throw err
      })
    })
    this.subscribeToPusher()
  },
  methods: {
    setHeader(token) {
      api.defaults.headers.common.Authorization = token || ''
    },
    subscribeToPusher() {
      const pusher = new Pusher(this.pusherKey, {
        cluster: this.pusherCluster
      })
      pusher.subscribe('merchant')
      pusher.bind('update-from-admin', (data) => {
        const { dashboardUserIds, message, urgency, expiresAt } = data
        if (dashboardUserIds && dashboardUserIds.includes(this.getID)) {
          store.commit('setAdminMessage', message)
          store.commit('setAdminMessageUrgency', urgency)
          store.commit('setAdminMessageExpiresAt', expiresAt)
          store.commit('setAdminMessageId', data.messageId)
        }
      })
    }
  },
  render: (h) => h(App)
}).$mount('#app')
