import Vue from 'vue'
import moment from 'moment'
import * as helpers from '@/utils/format-helpers'
import momentTimezone from 'moment-timezone'

// TODO extract filter logic to format-helpers or datetime-helpers so it's easily testable / reusable elsewhere

Vue.filter('formatPhone', helpers.formatPhoneNumber)

Vue.filter('titlelize', helpers.titlelize)

Vue.filter('decimalToPercentage', (val, blankFiller = '—') => {
  return `${helpers.decimalToPercentage(val, blankFiller)}%`
})

Vue.filter('addPercentage', (val, blankFiller = '—') => {
  if (val) {
    return `${val}%`
  }
  return blankFiller
})

Vue.filter('currencyFormatter', (val, blankFiller = '—') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  if (!val || val == 0) return blankFiller !== '—' ? blankFiller : blankFiller
  return formatter.format(val).toString()
})

Vue.filter('fundedFormatter', (val) => {
  if (!val || val == 0 || val === '$0.00') return '—'
  return val
})

Vue.filter('fundedStatusFormatter', (val) => {
  if (!val || val == 0 || val === '--') return '—'
  return val
})

Vue.filter('currencyFormatterNumber', (val, blankFiller = '—') => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  if (!val || val == 0) return blankFiller !== '—' ? blankFiller : blankFiller
  return formatter.format(val).toString()
})

Vue.filter('formatId', (val, prefix) => {
  if (val.includes(prefix)) {
    return val.replace(prefix, '')
  }
  return val || ''
})

Vue.filter('formatDate', (val) => {
  if (!val || (val && val.toLowerCase() === 'invalid date')) {
    return '—'
  } else {
    return val !== 'N/A' ? moment(val).format('ll') : val
  }
})

Vue.filter('formatToday', (val) => {
  if (!val) {
    return '—'
  } else if (moment(val).isSame(moment(), 'd')) {
    return 'Today'
  } else {
    return moment(val).format('ll')
  }
})

Vue.filter('formatFundedDate', (val) => {
  if (!val) {
    return '—'
  } else {
    const formatted = moment(val, 'DD-MM-YYYY').format('ll')
    return formatted
  }
})
Vue.filter('storeFilter', (val, stores = []) => {
  let store = stores.find((store) => store.id === val)
  if (store && store.name.includes('- ')) {
    return store ? store.name.split('- ').slice(-1).pop() : '-'
  } else {
    return store ? store.name.split(': ').slice(-1).pop() : '-'
  }
})

Vue.filter('formatDateTz', (val, timezone) => {
  if (!val) {
    return '—'
  }
  return momentTimezone.tz(val, timezone).format('MM/DD/YYYY')
})

Vue.filter('formatAppDate', (val) => {
  if (!val) {
    return '—'
  }
  const formattedDate = new Date(val.substring(0, val.length - 1))
  return moment(formattedDate).format('MM/DD/YYYY')
})
