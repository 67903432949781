import api from '@/utils/api'

export const login = ({ commit, dispatch }, data) => {
  return new Promise((resolve, reject) => {
    api
      .post('auth/login', data)
      .then(({ data }) => {
        const { user, token } = data.data.login_data
        commit('setUser', user)
        commit('setToken', token)
        commit('setAuthState', true)
        resolve(true)
      })
      .catch((err) => {
        dispatch('logout')
        if (err.response) {
          reject(err.response.data.message)
        } else if (err.request) {
          reject(err.request)
        } else {
          reject(err.message)
        }
        reject(err)
      })
  })
}

export const logout = ({ commit }) => {
  commit('setAuthState', false)
  commit('setToken', '')
  commit('setUser', {})

  localStorage.clear()
}

export const setLogin = ({ commit }, user) => {
  const userObject = JSON.parse(user)
  commit('setUser', userObject)
  commit('setToken', userObject.token)
  commit('setAuthState', true)
}
