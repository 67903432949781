import api from '@/utils/api'

export default {
  resetBlacklistedRoutingNumbers: async ({ commit, rootGetters }) => {
    try {
      const response = await api.get('/')

      const pageData = JSON.parse(JSON.stringify(rootGetters.getPageData))
      const originalBlacklistedRoutingNumbers = response.data.data.field_data.blacklisted_routing_numbers
      pageData['blacklisted_routing_numbers'] = originalBlacklistedRoutingNumbers

      commit('setPageData', pageData, { root: true })
    } catch (err) {
      return err
    }
  },
  modifyBlacklistedRoutingNumbers: ({ commit, rootGetters }, payload) => {
    const pageData = JSON.parse(JSON.stringify(rootGetters.getPageData))
    pageData['blacklisted_routing_numbers'] = payload

    commit('setPageData', pageData, { root: true })
  }
}
